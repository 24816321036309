import { motion } from 'framer-motion';
import { FaShieldAlt, FaStream, FaCheckCircle } from 'react-icons/fa';
import { Card, CardHeader, CardTitle, CardContent } from "../@/components/ui/card";

const features = [
  {
    title: "Secure Tokenization",
    description: "Protect sensitive invoice data with our advanced tokenization technology.",
    icon: FaShieldAlt
  },
  {
    title: "Streamlined Processes",
    description: "Simplify your invoice management with our intuitive platform.",
    icon: FaStream
  },
  {
    title: "Compliance Ready",
    description: "Stay compliant with industry standards and regulations effortlessly.",
    icon: FaCheckCircle
  }
];

const Features = () => {
  return (
    <section className="py-16 bg-slate-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-16 text-slate-900">Our Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card className="h-full transition-shadow hover:shadow-lg">
                <CardHeader>
                  <feature.icon className="text-4xl text-blue-600 mb-4" />
                  <CardTitle className="text-xl font-semibold text-slate-800">{feature.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-slate-600">{feature.description}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;