import Head from 'next/head';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Submit from '../components/Submit';


export default function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-100">
      <Head>
        <title>Mezzpro - Secure Invoice Tokenization</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Navbar />
      
      <motion.main 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto px-4 space-y-20 py-10"
      >
        <Hero />
        <Features />
        <Submit />
      </motion.main>

      <footer className="bg-slate-900 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          © 2024 Mezzpro. All rights reserved.
        </div>
      </footer>
    </div>
  );
}