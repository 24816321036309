import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../@/components/ui/dialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../@/components/ui/table";
import { Input } from "../@/components/ui/input";
import { Button } from "../@/components/ui/button";
import { useToast } from "../@/components/ui/use-toast";
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

interface SubmitTerm {
  id: number;
  term: string;
}

interface SubmitTablePopupProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const SubmitTablePopup: React.FC<SubmitTablePopupProps> = ({ open, onOpenChange }) => {
  const [terms, setTerms] = useState<SubmitTerm[]>([]);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editedTerm, setEditedTerm] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    if (open) {
      fetchTerms();
    }
  }, [open]);

  const fetchTerms = async () => {
    try {
      const response = await fetch(`${publicRuntimeConfig.backendUrl}/submit/terms`);
      if (response.ok) {
        const data = await response.json();
        setTerms(data.submit_terms);
      } else {
        toast({
          title: "Error",
          description: "Failed to fetch submit terms",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error('Error fetching terms:', error);
      toast({
        title: "Error",
        description: "An error occurred while fetching terms",
        variant: "destructive",
      });
    }
  };

  const handleEdit = (id: number, term: string) => {
    setEditingId(id);
    setEditedTerm(term);
  };

  const handleSave = async (id: number) => {
    try {
      const response = await fetch(`${publicRuntimeConfig.backendUrl}/submit/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ term: editedTerm }),
      });

      if (response.ok) {
        const updatedTerm = await response.json();
        setTerms(terms.map(t => t.id === id ? { ...t, term: updatedTerm.term } : t));
        setEditingId(null);
        setEditedTerm('');
        toast({
          title: "Success",
          description: "Term updated successfully",
        });
      } else {
        const data = await response.json();
        toast({
          title: "Error",
          description: data.detail || "Failed to update term",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error('Error updating term:', error);
      toast({
        title: "Error",
        description: "An error occurred while updating the term",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (id: number) => {
    try {
      console.log(`Attempting to delete term with id: ${id}`);
      const response = await fetch(`${publicRuntimeConfig.backendUrl}/submit/${id}`, {
        method: 'DELETE',
      });
      console.log(`Delete URL: ${publicRuntimeConfig.backendUrl}/submit/${id}`);
      if (response.ok) {
        setTerms(terms.filter(t => t.id !== id));
        toast({
          title: "Success",
          description: "Term deleted successfully",
        });
      } else {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        toast({
          title: "Error",
          description: errorData.detail || `Failed to delete term. Status: ${response.status}`,
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error('Error deleting term:', error);
      toast({
        title: "Error",
        description: "An error occurred while deleting the term",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Submit Table</DialogTitle>
        </DialogHeader>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>Term</TableHead>
              <TableHead>Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {terms.map((term) => (
              <TableRow key={term.id}>
                <TableCell>{term.id}</TableCell>
                <TableCell>
                  {editingId === term.id ? (
                    <Input
                      value={editedTerm}
                      onChange={(e) => setEditedTerm(e.target.value)}
                    />
                  ) : (
                    term.term
                  )}
                </TableCell>
                <TableCell>
                  {editingId === term.id ? (
                    <Button onClick={() => handleSave(term.id)}>Save</Button>
                  ) : (
                    <>
                      <Button onClick={() => handleEdit(term.id, term.term)} className="mr-2">Edit</Button>
                      <Button onClick={() => handleDelete(term.id)} variant="destructive">Delete</Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitTablePopup;